import { graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import get from 'lodash/get'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Index = ({ data, location }) => {
  const work = get(data, 'allPrismicCaseStudy.work')
  return (
    <Layout location={location}>
      <Meta title="Penso Co. - A small studio that creates digital products and experiences." location="/" />
        <header>
          <div className="container intro">
            <div className="row">
              <div className="col col-lg-10">
                <h1 className="tagline">
                  We are a small studio that creates digital products and experiences.
                </h1>
                <h3>
                  Learn more about{' '}
                  <AniLink
                    to="/what-we-do"
                    className="bold text-nowrap"
                    cover
                    duration={1}
                    bg="#b88343"
                  >
                    what we do
                    <span className="icon-right-open" />
                  </AniLink>
                </h3>
              </div>
            </div>
          </div>
          <div className="header-bg" />
        </header>

        <div className="container selected-works">
          <div className="row">
            <div className="col-12">
              <h4 className="text-uppercase">Selected Works &rsquo;14-19</h4>
            </div>
          </div>
          <div className="row d-flex justify-content-between">
            {work.map(({ project }, i) => (

              <div
                key={i}
                className='col-md-6 work'
              >
                <AniLink
                  cover
                  duration={1}
                  to={`/work/${project.uid}`}
                  title={project.data.title}
                  bg="#b88343"
                >
                  <div className="image-contain">
                    <Img
                      fluid={{...project.data.featured_image.localFile.childImageSharp.standard, aspectRatio: 16/9}}
                      alt={`${project.data.title} Case Study`}
                    />
                    <Img
                      className="image-hover"
                      fluid={{...project.data.featured_image.localFile.childImageSharp.golden, aspecRatio: 16/9}}
                    />
                  </div>
                  <h2>{project.data.title}</h2>
                  <p>{project.data.summary}</p>
                </AniLink>
              </div>
            ))}
            </div>
          <div className="row py-md-5">
            <div className="col">
              <AniLink
                to="/work"
                className="view--all"
                cover
                duration={1}
                bg="#b88343"
              >
                View all Case Studies
                <span className="icon-right-open" />
              </AniLink>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    allPrismicCaseStudy(
      sort: { fields: [data___date_launched], order: DESC }
      limit: 4
    ) {
      work: edges {
        project: node {
          uid
          data {
            title
            summary
            date_launched(formatString: "YYYY/MM/DD")
            featured_image {
              localFile {
                childImageSharp {
                  standard: fluid(background: "#b88343", maxWidth: 570, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                  golden: fluid(
                    duotone: { highlight: "#dbc1a1", shadow: "#12252d" },
                    maxWidth: 570,
                    quality: 90
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
